import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
// DONT REMOVE REACTPIXEL IMPORT
import ReactPixel from 'react-facebook-pixel';
import Store from './Domain/Redux/Store/Store';
import './App.css';
import { Routes } from './Routes/Routes';
import InitialSetup from './Components/Atoms/InitialSetup/InitialSetup';
import { GOOGLE_ANALITY_MEASURENMENT_ID } from './Domain/Settings/Keys/Keys';

const App = () => {
  useEffect(() => {
    const pagePath = window.location.pathname;
    window.gtag('config', GOOGLE_ANALITY_MEASURENMENT_ID, {
      page_path: pagePath,
      debug_mode: true
    });
  }, []);

  return (
    <Provider store={Store}>
      <InitialSetup>
        <Routes />
      </InitialSetup>
    </Provider>
  );
};

export default App;
