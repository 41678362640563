/*
 * this file save the corresponding name of the differents
 * (screens / pages) used for grouping the components and make just a single request
 */

export const COMPONENTS = {
  FOOTER: 'Footer',
  LOGO: 'Logo',
  MODAL_LITE_T: 'ModalLiteT',
  NAVIGATION_BAR: 'NavigationBar',
  PREVIEW_MODAL: 'PreviewModal',
  CHANGE_PASSWORD_SCREEN: 'ChangePasswordScreen',
  COURSES_SCREEN: 'CoursesScreen',
  ENGLISH_TEST_RESULT_SCREEN: 'EnglishTestResultScreen',
  ENGLISH_TEST_SCREEN: 'EnglishTestScreen',
  HOME_SCREEN: 'HomeScreen',
  JOB_SCREEN: 'JOB_SCREEN',
  JOBS_SCREEN: 'JOBS_SCREEN',
  LITE_THINKING_SCREEN: 'LiteThinkingScreen',
  NEXT_EVENT_SCREEN: 'NextEventScreen',
  PRE_REGISTER_SCREEN: 'PreRegisterScreen',
  SERVICES_SCREEN: 'ServicesScreen',
  CONSULTING_SERVICE_SCREEN: 'ConsultingServiceScreen',
  COURSE_INFO_SCREEN: 'COURSE_INFO_SCREEN',
  INTERES_AREA_SCREEN: 'INTERES_AREA_SCREEN',
  ORGANIZATIONAL_CONSULTING_SCREEN: 'OrganizationalConsultingScreen',
  TI_SERVICE_SCREEN: 'TIServiceScreen',
  TI_SOLUTIONS_SCREEN: 'TISolutionsScreen',
  TRAININGS_SCREEN: 'TRAININGS_SCREEN',
  CREDENTIALS_HOME_SECTION: 'CredentialsHomeSection',
  JOBS_HOME_SECTION: 'JobsHomeSection',
  JOBS_SECTION: 'JobsSection',
  LMS_HOME_SECTION: 'LMSHomeSection',
  LT_IN_THE_WORLD_SECTION: 'LTInTheWorldSection',
  OUR_PARTNERS: 'OurPartners',
  OUR_PORTFOLIO_SECTION: 'OUR_PORTFOLIO_SECTION',
  TEST_ENGLISH_SECTION: 'TestEnglishSection',
  WHY_LT_SECTION: 'WhyLTSection',
  LT_NEWS_VIEWER: 'LT_NEWS_VIEWER',
  ENGLISH_SCREEN: 'ENGLISH_SCREEN',
  HOME_IA_SECTION: 'HOME_IA_SECTION',
  HOME_SERVICES_SECTION: 'HOME_SERVICES_SECTION',
  HOME_REINVENT_SECTION: 'HOME_REINVENT_SECTION',
  DETAIL_COURSE_INFORMATION: 'DETAIL_COURSE_INFORMATION',
  HOME_INTRODUCTORY_BANNER_SECTION: 'HOME_INTRODUCTORY_BANNER_SECTION',
  FORM_INTEREST_REGISTER: 'FORM_INTEREST_REGISTER', //InterestRegisterForm
  HOME_VIDE_SECTION: 'HOME_VIDE_SECTION',
  HOME_FEEDBACK_SECTION: 'HOME_FEEDBACK_SECTION',
  COURSE_MOTIVATE_SECTION: 'COURSE_MOTIVATE_SECTION',
  FORM_REGISTER_POPUP: 'FORM_REGISTER_POPUP',
  COURSE_CARD_BUY: 'COURSE_CARD_BUY',
  DETAIL_INTRODUCTORY_ARTICLE: 'DETAIL_INTRODUCTORY_ARTICLE',
  HOME_INTRODUCTORY: 'HOME_INTRODUCTORY',
  PAYMENTS_METHODS_SECTION: 'PAYMENTS_METHODS_SECTION',
  CERTIFICATION_CARROUSEL_SECTION: 'CERTIFICATION_CARROUSEL_SECTION',
  CONTENT_CARROUSEL_SECTION: 'CONTENT_CARROUSEL_SECTION',
  COURSE_BADGED_SECTION: 'COURSE_BADGED_SECTION',
  TI_SOFTWARE_SERVICES_SECTION: 'TI_SOFTWARE_SERVICES_SECTION',
  CARD_DETAIL_COURSE: 'CARD_DETAIL_COURSE',
  MEMBERSHIP_INTEREST_SECTION: 'MEMBERSHIP_INTEREST_SECTION',
  SERVICE_PROGRAM_SECTION: 'SERVICE_PROGRAM_SECTION',
  CONSULTANTS_LT_SECTION: 'CONSULTANTS_LT_SECTION',
  STRATEGIC_PARTNERS_SECTION: 'STRATEGIC_PARTNERS_SECTION',
  BENEFECITS_CERTIFICATIONS_SECTION: 'BENEFECITS_CERTIFICATIONS_SECTION',
  YOUR_COMPANY_LT_DESEVERE: 'YOUR_COMPANY_LT_DESEVERE',
  SOLVE_QUESTIONS_SECTION: 'SOLVE_QUESTIONS_SECTION',
  BANNER_PRINCIPAL_SECTION: 'BANNER_PRINCIPAL_SECTION'
};
