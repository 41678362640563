import { HttpClient } from '../Fetch/Fetch';
import { GET_PARAMETERS_PATH } from '../../../Redux/Paths';
import store from '../../../Redux/Store/Store';

const LANGUAGES = {
  STANDARD: 1,
  SPANISH: 2,
  ENGLISH: 3
};

export const LENGUAGES_LIST = {
  es: LANGUAGES.SPANISH,
  en: LANGUAGES.ENGLISH
};

export const getParametersPath = (codeList = [], language) => {
  return new Promise((response, reject) => {
    if (!language) return reject('');
    HttpClient.GET(GET_PARAMETERS_PATH, { code: codeList, language })
      .then((res) => {
        if (res?.length > 0) {
          return response(res);
        }
        return response([]);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const getParametersStandard = (codeList = []) => {
  return getParametersPath(codeList, LANGUAGES.STANDARD);
};

export const getParametersSpanish = (codeList = []) => {
  const state = store.getState();
  const _language = state?.languageTraslate?.language;
  return getParametersPath(codeList, _language?.value);
};

export const getParametersEnglish = (codeList = []) => {
  return getParametersPath(codeList, LANGUAGES.ENGLISH);
};

export const searchParameter = (parameters = [], target = '', key = '') => {
  return parameters.find(({ code }) => code === target)[key];
};

export const getParametersAsObject = (parameterList = []) => {
  const parametersAsObject = {};
  for (const parameter of parameterList) {
    parametersAsObject[parameter.code] = parameter;
  }
  return parametersAsObject;
};

/*
 * function to get the parameters as the above function
 * but if there are many parameters with the same code then
 * return them grouped
 */
export const getParametersAndDuplicateListAsObject = (parameterList = []) => {
  const resultObject = {};
  for (const parameter of parameterList) {
    if (resultObject[parameter.code]) {
      if (Array.isArray(resultObject[parameter.code])) {
        resultObject[parameter.code] = [...resultObject[parameter.code], parameter];
      } else {
        resultObject[parameter.code] = [resultObject[parameter.code], parameter];
      }
    } else {
      resultObject[parameter.code] = parameter;
    }
  }
  return resultObject;
};
