import { SET_LANGUAGE, SET_LANGUAGE_LIST } from '../Actions/ActionTypes/ActionTypesLenguage';

let storedLanguage = localStorage.getItem('language_');

const initialState = {
  language:
    // !['undefined', 'null'].includes(storedLanguage)
    //   ? JSON.parse(storedLanguage)
    //   :
    {
      // id: 3,
      // name: 'Ingles',
      // description: 'En',
      // value: 3
    },
  languageList: []
};

function languageTraslate(state = initialState, action) {
  switch (action.type) {
    case SET_LANGUAGE:
      return { ...state, language: action.payload };
    case SET_LANGUAGE_LIST:
      return { ...state, languageList: action.payload };
    default:
      return state;
  }
}

export default languageTraslate;
