import { LOCATION } from '../../Settings/Location/Location';
import { PROFILE, LOGIN } from '../Actions/ActionTypes/ActionTypes';

function loadStateResult(variables, index, payload, result) {
  if (!variables[index]) {
    return result;
  } else {
    const parameters = variables[index]; // array de objetos con nombre variable y apuntador en el payload
    let payloadForVariable = payload;
    for (const subObj of parameters.payload.split('.')) {
      if (subObj.includes('[')) {
        payloadForVariable =
          payloadForVariable[subObj.split('[')[0]][parseInt(subObj.split('[')[1].split(']')[0])];
      } else {
        payloadForVariable = payloadForVariable[subObj];
      }
    }
    result[parameters.variable] = payloadForVariable;
    return loadStateResult(variables, index + 1, payload, result);
  }
}

export function reducerGeneral(language, status, state, payload, variables, type) {
  if (status === 200) {
    const result = loadStateResult(variables, 0, payload, {});
    if (type === PROFILE) {
      window.localStorage.setItem('username', payload.data.username);
      window.localStorage.setItem('first_name', payload.data.first_name);
      window.localStorage.setItem('last_name', payload.data.last_name);
      window.localStorage.setItem('pk', payload.data.pk);
      window.localStorage.setItem('email', payload.data.email);
      result.status = status;
    }
    return { ...state, ...result };
  }
  if (status === 401 && type === LOGIN) {
    return {
      ...state,
      status: 401,
      message: LOCATION[LOGIN][language].errors.USERINVALID
    };
  }
  if (!status) {
    const stateResult = {};
    for (const variable of variables) {
      stateResult[variable.name] = variable.value;
    }
    return { ...state, ...stateResult };
  } else {
    return {
      ...state,
      message: LOCATION.general[language].SERVICEERROR,
      status
    };
  }
}
