import './Header.css';
import '../../../Generals/Styles/FormContainerStyle.css';

import { Col, Nav, Navbar } from 'react-bootstrap';
import {
  // FRONTENDCRM_URL,
  FRONTEND_URL,
  FRONTMICROSITE,
  GET_NEWS_LIST_PATH,
  FRONTENDLMS_URL
} from '../../../Domain/Redux/Paths';
import { HashLink, NavHashLink } from 'react-router-hash-link';
import React, { useEffect, useRef, useState } from 'react';
import { faBars, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  isMobileMatchMedia,
  scrollToSectionWithOffset
} from '../../../Core/Hooks/HooksGenerals/UseMediaQuery';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Logo } from '../../../Resources/Images/ImagesLogo';
import { STRATEGIC_LINES_VIEWS } from '../../../Domain/Settings/Constants/Constants';
import classNames from 'classnames';
import { useMediaQuery } from '../../../Core/Hooks/HooksGenerals/UseMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import GeneralSubElement from '../../Atoms/HeaderSubElement/GeneralSubElement';
import globeTraslate from '../../../assets/globeTraslate.svg';
import globeTraslateYellow from '../../../assets/globeTraslateYellow.svg';
import Spinner from '../../Atoms/Spinner/Spinner';
import { setLanguage } from '../../../Domain/Redux/Actions/Actions/ActionLenguageNavigator';

import { COMPONENTS } from '../../../Domain/Settings/Constants/ComponentsParameter/ComponentsParameter';
import { useGetComponentParameters } from '../../../Core/Hooks/HooksParameters/useGetComponentParameters';
import { setMobileMenuStyle } from '../../../Domain/Redux/Actions/Actions/ActionsMobileMenu';
import { HttpClient } from '../../../Domain/Utils/ApiUtilies/Fetch/Fetch';

const _setLanguage = (browserLanguage, lenguagesList = [], dispatch) => {
  if (!lenguagesList?.length) {
    return;
  }

  const object_ = lenguagesList.find((item) => item.description === browserLanguage);
  dispatch(setLanguage(object_));
};

// Header component that contains the main navbar and internal navbar
const Header = (props) => {
  const parameters = useGetComponentParameters(COMPONENTS.NAVIGATION_BAR);

  const { showSearchWrapper, setShowSearchWrapper } = props;
  const isBgTransparent = useSelector((store) => store.stylesState.transparentBackground);
  const language = useSelector((store) => store.languageTraslate.language);
  const lenguagesList = useSelector((store) => store.languageTraslate.languageList);

  const isMenuOpen = useSelector((store) => store.mobileMenu.mobileMenuOpened);

  const [expanded, setExpanded] = useState(false);
  const [isMenuCollapsable, setIsMenuCollapsable] = useState(true);
  const navbarRef = useRef(null);
  const [showTraslateOptions, setshowTraslateOptions] = useState(false);
  const [showTraslateOptionsMobile, setshowTraslateOptionsMobile] = useState(false);
  // const [lenguagesList, setLenguagesList] = useState([]);
  // testing
  const width = useMediaQuery();

  const dispatch = useDispatch();
  const [newsInfo, setNewsInfo] = useState(null);

  useEffect(() => {
    function isNewsInURL() {
      const currentURL = window.location.href;
      return currentURL.includes('news');
    }
    if (isNewsInURL()) {
      if (newsInfo !== null) return;
      const fetchNewsList = async () => {
        try {
          const newsList = await HttpClient.GET(GET_NEWS_LIST_PATH);
          setNewsInfo(newsList);
        } catch (error) {
          console.debug('>>>> usenewsList: error=', error);
        }
      };

      fetchNewsList();
    }
  }, [newsInfo]);

  useEffect(() => {
    if (!isMenuOpen) {
      setExpanded(false);
    }
  }, [isMenuOpen]);

  useEffect(() => {
    const matchedMedia = isMobileMatchMedia();
    const firedMatchMedia = () => {
      setIsMenuCollapsable(matchedMedia.matches);
    };
    firedMatchMedia();
  }, [width]);

  // Get parameters from the backend to set the logo and add event listeners
  useEffect(() => {
    const matchedMedia = isMobileMatchMedia();
    const firedMatchMedia = () => {
      setIsMenuCollapsable(matchedMedia.matches);
    };
    firedMatchMedia();
    const handleClickOutsideNavbar = (event) => {
      if (!navbarRef?.current?.contains(event.target)) {
        // collapseMenu();
      }
    };
    matchedMedia.addEventListener('EventTarget', firedMatchMedia); // @see https://github.com/mdn/sprints/issues/858#issuecomment-515238645
    document.addEventListener('click', handleClickOutsideNavbar, {
      capture: true
    });

    return () => {
      matchedMedia.removeEventListener('EventTarget', firedMatchMedia);
      document.removeEventListener('click', handleClickOutsideNavbar, {
        capture: true
      });
    };
  }, []);

  // Items for the main navbar in mobile
  const ITEMS_MAIN_NAVBAR = [
    {
      to: '#',
      id: 'LiteThinking',
      onClick: () => {
        window.location.href = '/lite-thinking';
      },
      // * parameter code: INF_MSG_45
      label: parameters?.INF_MSG_45?.value || <Spinner />
    },
    {
      to: '#',
      id: 'FabricaDeSoftware',
      onClick: () => {
        window.location.href = `/${STRATEGIC_LINES_VIEWS.TI_SOLUTIONS}`;
      },
      // * parameter code: INF_MSG_40
      label: parameters?.INF_MSG_40?.value || <Spinner />
    },
    {
      to: '#',
      id: 'Consultoria',
      onClick: () => {
        window.location.href = `/${STRATEGIC_LINES_VIEWS.ORGANIZATIONAL_CONSULTING}`;
      },
      // * parameter code: INF_MSG_39
      label: parameters?.INF_MSG_39?.value || <Spinner />
    },
    {
      to: '#',
      id: 'Universidad',
      onClick: () => {
        window.location.href = '/trainings';
      },
      // * parameter code: INF_MSG_38
      label: parameters?.INF_MSG_38?.value || <Spinner />
    },
    {
      to: '#',
      id: 'IniciarSesion',
      onClick: () => {
        window.location.href = `${FRONTEND_URL}/services`;
      },
      // * parameter code: INF_MSG_41
      label: parameters?.INF_MSG_41?.value || <Spinner />
    },
    {
      to: '#',
      id: 'ProximosEventos',
      onClick: () => {
        window.location.href = '/next-events';
      },
      // * parameter code: INF_MSG_42
      label: parameters?.INF_MSG_42?.value || <Spinner />
    },
    {
      to: '#',
      id: 'Pagos',
      onClick: () =>
        window.open(
          `${FRONTMICROSITE}/checkout?language=${language?.description?.toLowerCase()}`,
          '_blank'
        ),
      // * parameter code: INF_MSG_43
      label: <span>{parameters?.INF_MSG_43?.value || <Spinner />}</span>
    },
    {
      to: '#',
      id: 'LlamameAhora',
      onClick: () => {
        window.location.href = `${FRONTEND_URL}/jobs`;
      },
      // * parameter code: INF_MSG_44
      label: <span>{parameters?.INF_MSG_44?.value || <Spinner />}</span>
    }
  ];

  // Items for the main navbar in desktop
  const ITEMS_MAIN_NAVBAR_NC = [
    {
      to: '/lite-thinking',
      id: 'LiteThinking_nc',
      onClick: () => {
        window.location.href = '/lite-thinking';
      },
      // * parameter code: INF_MSG_45
      label: parameters?.INF_MSG_45?.value || <Spinner />
    },
    {
      to: `/${STRATEGIC_LINES_VIEWS.TI_SOLUTIONS}`,
      family: ['/ti-services'],
      id: 'FabricaDeSoftware_nc',
      onClick: () => {
        window.location.href = `/${STRATEGIC_LINES_VIEWS.TI_SOLUTIONS}`;
      },
      // * parameter code: INF_MSG_40
      label: parameters?.INF_MSG_40?.value || <Spinner />
    },
    {
      to: `/${STRATEGIC_LINES_VIEWS.ORGANIZATIONAL_CONSULTING}`,
      family: ['/consulting-services'],
      id: 'Consultoria_nc',
      onClick: () => {
        window.location.href = `/${STRATEGIC_LINES_VIEWS.ORGANIZATIONAL_CONSULTING}`;
      },
      // * parameter code: INF_MSG_39
      label: parameters?.INF_MSG_39?.value || <Spinner />
    },
    {
      to: '/trainings',
      family: ['/interest-area', '/course-info'],
      id: 'Universidad',
      onClick: () => {
        window.location.href = '/trainings';
      },
      // * parameter code: INF_MSG_38
      label: parameters?.INF_MSG_38?.value || <Spinner />
    },
    {
      to: `/services`,
      id: 'IniciarSesion_nc',
      onClick: () => {
        window.location.href = `${FRONTEND_URL}/services`;
      },
      // * parameter code: INF_MSG_41
      label: parameters?.INF_MSG_41?.value || <Spinner />
    },
    {
      to: `/jobs`,
      family: ['/job'],
      id: 'jobs_nc',
      onClick: () => {
        window.location.href = `${FRONTEND_URL}/jobs`;
      },
      // * parameter code: INF_MSG_44
      label: parameters?.INF_MSG_44?.value || <Spinner />
    }
  ];

  // Items for the intro navbar in mobile
  const ITEMS_INTRO_NAVBAR = [
    {
      to: '#',
      id: 'learningPlatform',
      onClick: () => {
        window.open(`${FRONTENDLMS_URL}/${language?.description?.toLowerCase()}/login`, '_blank');
      },
      // * parameter code: INF_MSG_46
      label: parameters?.INF_MSG_46?.value || <Spinner />
    },
    {
      to: '#',
      id: 'NEW_nc',
      // onClick: () => window.open(`${}?id=${newId}`),
      onClick: () => {
        window.location.href = `news?id=${
          newsInfo?.results?.[newsInfo?.results?.length - 1]?.new__id
        }`;
      },
      label: parameters?.INF_MSG_32?.value || <Spinner />
    },
    // {
    //   to: "#",
    //   id: "CMR",
    //   onClick: () => window.open(`${FRONTENDCRM_URL}`),
    //   label: parameters?.INF_MSG_46?.value ? "CRM" : <Spinner />,
    // },
    {
      to: '#',
      id: 'Contáctenos',
      onClick: () => window.open(`${FRONTEND_URL}/english-challenge`, '_blank'),
      // * parameter code: FOOTER_CNU
      label: parameters?.ENG_CHALLENGE?.value || <Spinner />
    }
  ];

  // Items for the intro navbar in desktop
  const ITEMS_INTRO_NAVBAR_NC = [
    // {
    //   to: "#",
    //   id: "CMR_nc",
    //   onClick: () => window.open(`${FRONTENDCRM_URL}`),
    //   label: parameters?.INF_MSG_46?.value ? (
    //     <strong>CRM</strong>
    //   ) : (
    //     <strong>
    //       <Spinner />
    //     </strong>
    //   ),
    // },
    {
      to: '#',
      id: 'Contáctenos',
      onClick: () => window.open(`${FRONTEND_URL}/english-challenge`, '_blank'),
      // * parameter code: FOOTER_CNU
      label: <strong>{parameters?.ENG_CHALLENGE?.value || <Spinner />}</strong>
    },
    {
      to: '#',
      id: 'nextEvents_nc',
      onClick: () => {
        window.location.href = `${FRONTEND_URL}/next-events`;
      },
      // * parameter code: INF_MSG_42
      label: <strong>{parameters?.INF_MSG_42?.value || <Spinner />}</strong>
    },
    {
      to: '#',
      id: 'NEW_nc',
      // onClick: () => window.open(`${}?id=${newId}`),
      onClick: () => {
        window.location.href = `news?id=${
          newsInfo?.results?.[newsInfo?.results?.length - 1]?.new__id
        }`;
      },
      label: <strong>{parameters?.INF_MSG_32?.value || <Spinner />}</strong>
    },
    {
      to: '#',
      id: 'Pagos',
      onClick: () =>
        window.open(
          `${FRONTMICROSITE}/checkout?language=${language?.description?.toLowerCase()}`,
          '_blank'
        ),
      // * parameter code: INF_MSG_43
      label: <strong>{parameters?.INF_MSG_43?.value || <Spinner />}</strong>
    },
    {
      to: '#',
      id: 'learningPlatform_nc',
      onClick: () => {
        window.open(`${FRONTENDLMS_URL}/${language?.description?.toLowerCase()}/login`, '_blank');
      },
      // * parameter code: INF_MSG_46
      label: <strong>{parameters?.INF_MSG_46?.value || <Spinner />}</strong>
    }
  ];

  const LogoLTNavbarBrand = () => (
    <Navbar.Brand className="me-auto logoNav">
      <HashLink smooth scroll={scrollToSectionWithOffset} to="/home#top" rel="nofollow">
        <img src={parameters?.LOGO_NO_BG?.desktop_image || Logo} alt="Lite Thinking" />
      </HashLink>
    </Navbar.Brand>
  );

  const NavbarToggleOption = () => (
    <Navbar.Toggle
      // aria-controls="basic-navbar-nav-2"
      onClick={() => {
        setExpanded(!expanded);
        dispatch(setMobileMenuStyle(!expanded));
        setShowSearchWrapper(false);
      }}
      className="border-0 text-lt-yellow">
      <FontAwesomeIcon icon={expanded ? faTimes : faBars} />
    </Navbar.Toggle>
  );

  const GoogleSearchInput = () => (
    <Navbar
      // aria-controls="basic-navbar-nav-2"
      onClick={() => {
        setShowSearchWrapper(!showSearchWrapper);
        setExpanded(false);
        dispatch(setMobileMenuStyle(false));
      }}
      className="border-0 items-intro-navbar">
      {parameters?.INF_MSG_146?.value && parameters?.INF_MSG_145?.value ? (
        <FontAwesomeIcon icon={showSearchWrapper ? faTimes : faSearch}></FontAwesomeIcon>
      ) : (
        <Spinner />
      )}

      {showSearchWrapper ? (
        <strong className="border-0 items-intro-navbar" style={{ marginLeft: '10px' }}>
          {' '}
          {parameters?.INF_MSG_146?.value}
        </strong>
      ) : (
        <strong className="border-0 items-intro-navbar" style={{ marginLeft: '10px' }}>
          {' '}
          {parameters?.INF_MSG_145?.value}
        </strong>
      )}
    </Navbar>
  );

  const isScroll = isBgTransparent && !isMenuCollapsable;
  const backGroundColor = isScroll ? 'transparent' : 'white';

  const LanguageSelector = () => (
    <Navbar
      // aria-controls="basic-navbar-nav-2"
      onClick={() => {
        setshowTraslateOptions(!showTraslateOptions);
        setExpanded(false);
      }}
      className="border-0 items-intro-navbar">
      <img
        className={classNames(
          'my-navbar p-0 shadow-md',
          isScroll ? 'white-image-globe' : 'black-image-globe'
        )}
        src={globeTraslate}
        alt="React Logo"
      />
      <span>{language?.description}</span>
    </Navbar>
  );

  return (
    <Navbar
      id="lt-navbar"
      ref={navbarRef}
      fixed="top"
      expand="lg"
      collapseOnSelect
      expanded={expanded}
      className={classNames('my-navbar p-0 shadow-md flex-column', {
        'bg-transparent': isScroll
      })}
      variant="dark">
      <div className="row col-12 nav-responsive-width navbar-intro py-2 py-lg-1 ">
        {isMenuCollapsable ? (
          <>
            <div style={{ marginLeft: '-13px' }} className="col-8">
              <LogoLTNavbarBrand />
            </div>

            <div
              style={{ marginLeft: '20px' }}
              className="col-2 d-flex justify-content-center text-lt-yellow">
              <GoogleSearchInput />
            </div>
            <div className="col-2 border-0 d-flex justify-content-center">
              <NavbarToggleOption />
            </div>
          </>
        ) : (
          <>
            <Navbar.Collapse className="justify-content-end mx-0" style={{ maxHeight: '30px' }}>
              <Nav>
                {ITEMS_INTRO_NAVBAR_NC.map(({ id, to, onClick, label, iconLeft = null }) => (
                  <Nav.Link
                    key={id}
                    to={to}
                    as={NavHashLink}
                    replace
                    smooth
                    scroll={scrollToSectionWithOffset}
                    onClick={onClick}
                    className="d-flex justify-content-center align-items-center text-center items-intro-navbar">
                    {iconLeft && (
                      <FontAwesomeIcon icon={iconLeft.icon} className={iconLeft.className || ''} />
                    )}
                    {label}
                  </Nav.Link>
                ))}
                <Nav.Link
                  aria-label="Search in Google API"
                  style={{ margin: '0', display: 'flex' }}>
                  <GoogleSearchInput />
                </Nav.Link>

                <Nav.Link
                  aria-label="Change language"
                  // style={{ margin: '0px', display: 'flex' }}
                >
                  <GeneralSubElement
                    className="border-0 items-intro-navbar"
                    // style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    {
                      <>
                        <Navbar
                          aria-label="Button to select language text"
                          // aria-controls="basic-navbar-nav-2"
                          onClick={() => {
                            setshowTraslateOptions(!showTraslateOptions);
                            setExpanded(false);
                            dispatch(setMobileMenuStyle(false));
                          }}
                          className="border-0 items-intro-navbar">
                          <img
                            style={{ marginLeft: '0px' }}
                            className={classNames(
                              'my-navbar language-icon',
                              isScroll ? 'white-image-globe' : 'black-image-globe'
                            )}
                            src={globeTraslate}
                            alt="Globe Logo"
                          />
                          <strong>
                            <span aria-label="Button to select language text">
                              {language?.name}
                            </span>
                          </strong>
                        </Navbar>
                      </>
                    }
                  </GeneralSubElement>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
            <div
              className={classNames(
                'menu__lenguages-desktop my-navbar shadow-md flex-column',
                !isScroll ? '' : 'background__color-white',
                !isMenuCollapsable && showTraslateOptions ? 'container__list__lenaguage' : null
              )}

              // style={!isMenuCollapsable && showTraslateOptions ? myComponentStyle : null}
            >
              {!isMenuCollapsable && showTraslateOptions && (
                <>
                  {lenguagesList?.map(
                    (
                      { description, value, name } //(element, index)
                    ) => (
                      <p
                        key={description}
                        lg
                        xs="6"
                        className="container__list__lenaguage-text"
                        role={'button'}
                        onClick={() => {
                          _setLanguage(description, lenguagesList, dispatch);
                          setshowTraslateOptions(false);
                          setshowTraslateOptionsMobile(false);
                        }}>
                        {description === language?.description && <strong>{name}</strong>}
                        {description !== language?.description && name}
                      </p>
                    )
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>
      <div className="col-12 nav-responsive-width navbar-primary py-0 py-lg-2">
        <Navbar.Collapse className="justify-content-between border-danger">
          {isMenuCollapsable ? (
            <>
              <div className="row">
                {ITEMS_MAIN_NAVBAR.map(({ id, to, onClick, label }) => (
                  <Col key={id} lg xs="12" className="container-nav-link">
                    <Nav.Link
                      id={id}
                      to={to}
                      as={NavHashLink}
                      replace
                      smooth
                      scroll={scrollToSectionWithOffset}
                      onClick={onClick}
                      className="text-black border-0">
                      {label}
                    </Nav.Link>
                  </Col>
                ))}
              </div>
              <div className="bg-black d-flex flex-column justify-content-center">
                {ITEMS_INTRO_NAVBAR.map(({ id, to, onClick, label, iconLeft = null }) => (
                  <div
                    key={id}
                    className="d-flex justify-content-center align-items-center text-center px-2">
                    <Nav.Link
                      to={to}
                      as={NavHashLink}
                      replace
                      smooth
                      scroll={scrollToSectionWithOffset}
                      // onClick={onClick}
                      className="text-lt-yellow py-2">
                      {iconLeft && (
                        <FontAwesomeIcon
                          icon={iconLeft.icon}
                          className={iconLeft.className || ''}
                        />
                      )}
                      {label}
                    </Nav.Link>
                  </div>
                ))}
                <GeneralSubElement className="border-0 items-intro-navbar">
                  {
                    <>
                      <Navbar
                        onClick={() => {
                          setshowTraslateOptionsMobile(!showTraslateOptionsMobile);
                        }}
                        className="border-0 items-intro-navbar justify-content-center">
                        <img
                          className={classNames(
                            'text-lt-yellow my-navbar ',
                            isScroll ? 'white-image-globe' : 'black-image-globe'
                          )}
                          src={globeTraslateYellow}
                          alt="React Logo"
                        />
                        <span className="text-lt-yellow">{language?.name}</span>
                      </Navbar>
                    </>
                  }
                </GeneralSubElement>
              </div>
              {showTraslateOptionsMobile && (
                <div className="row">
                  {lenguagesList.map(
                    (
                      { description, value, name } //(element, index)
                    ) => (
                      <Col
                        key={description}
                        lg
                        xs="6"
                        className="container__list__lenaguage-mobile bg-black text-lt-yellow"
                        onClick={() => {
                          _setLanguage(description, lenguagesList, dispatch);
                          setshowTraslateOptions(false);
                          setshowTraslateOptionsMobile(false);
                        }}>
                        {description === language?.description && <strong>{name}</strong>}
                        {description !== language?.description && name}
                      </Col>
                    )
                  )}
                </div>
              )}
            </>
          ) : (
            <>
              <LogoLTNavbarBrand />
              <Nav>
                {ITEMS_MAIN_NAVBAR_NC.map(({ id, to, onClick, label, family = [] }) => (
                  <Nav.Link
                    id={id}
                    key={id}
                    to={to}
                    as={NavHashLink}
                    replace
                    smooth
                    scroll={scrollToSectionWithOffset}
                    // onClick={onClick}
                    className={`d-flex justify-content-center align-items-center text-center ${
                      to === window.location.pathname || family.includes(window.location.pathname)
                        ? 'active-link'
                        : null
                    }`}>
                    {label}
                  </Nav.Link>
                ))}
              </Nav>
            </>
          )}
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default Header;
