import { useSelector } from 'react-redux';
import { HttpClient } from '../../Utils/ApiUtilies/Fetch/Fetch';
import { COURSES_LIST_PATH } from '../Paths';

const ACTIONS_ID = 'COURSES_DATA';
const actionType = {
  LOADER: `${ACTIONS_ID}_LOADER`,
  LIST: `${ACTIONS_ID}_LIST`,
  ITEM: `${ACTIONS_ID}_ITEM`
};

// ------------------------------------
// Pure Actions
// ------------------------------------
const setLoader = (loader) => ({
  type: actionType.LOADER,
  payload: {
    loader
  }
});
const setList = (count, results) => ({
  type: actionType.LIST,
  payload: {
    count,
    results
  }
});
// const setItem = (item) => ({
//   type: actionType.ITEM,
//   payload: {
//     item
//   }
// })

// ------------------------------------
// Actions
// ------------------------------------
const getCoursesByPortfolio = (portfolioId) => async (dispatch, getStore) => {
  dispatch(setLoader(true));
  const languageKey = useSelector((store) => store.languageTraslate.language) || {
    id: 3,
    name: 'Ingles',
    description: 'En',
    value: 3
  };
  try {
    const response = await HttpClient.GET(COURSES_LIST_PATH, {
      minimal_data: true,
      unpaginated: true,
      course__portfolio_id: portfolioId,
      lenguage: languageKey.value
    });
    dispatch(setList(response.count, response.results));
  } catch (error) {
    console.debug('>>>> portfolios.getCoursesByPortfolio: error=', error);
  } finally {
    dispatch(setLoader(false));
  }
};

export const actions = {
  getCoursesByPortfolio
};

export const reducers = {
  [actionType.LOADER]: (state, { loader }) => {
    return {
      ...state,
      courses: { ...state.courses, loader }
    };
  },
  [actionType.LIST]: (state, { count, results }) => {
    return {
      ...state,
      courses: { ...state.courses, count, results }
    };
  },
  [actionType.ITEM]: (state, { item }) => {
    return {
      ...state,
      courses: { ...state.courses, item }
    };
  }
};

export const initialState = {
  courses: {
    loader: false,
    count: 0,
    item: null,
    results: []
  }
};
