export const LOCATION = {
  REGISTER: {
    es: {
      messages: {
        CREATE: 'Se ha enviado un código para que verifiques tu cuenta',
        SUCCESS: 'El registro ha sido satisfactorio'
      },
      errors: {
        USERALREADY:
          'Existe un cuenta registrada con el mismo correo electrónico. Por favor, utilizar un correo electrónico diferente.',
        PASSWORD: 'La contraseña es similar al correo',
        EMAILALREADY:
          'Existe un cuenta registrada con el mismo correo electrónico. Por favor, utilizar un correo electrónico diferente.',
        RECAPTCHA: 'El captcha no es válido'
      }
    }
  },
  LOGIN: {
    es: {
      errors: {
        USERINVALID: 'Contraseña o nombre de usuario incorrectos'
      }
    }
  },
  MATCH: {
    es: {
      errors: {
        NOMATCH:
          'Lo sentimos, no fue posible hacer match con esta persona, intenta de nuevo más tarde'
      }
    }
  },
  general: {
    es: {
      SERVICEERROR:
        'Tenemos problemas con nuestro servicio, puedes reportarnoslo a direccion@litethinking.com',
      TITLEWAIT: 'Un momento'
    }
  }
};
