import { BACKEND_URL } from '../../../Redux/Paths';

const DEFAULT_TOKEN = '75853e402747730aaffc3c3b2fbb687cc46111da';

const getRecaptcha = () => window.localStorage.getItem('reCaptcha');

const getToken = (customToken = null) => {
  // TODO: Pendiente validar por qué en la implementación ya existente utiliza Bearer con un token personalizado
  const token = customToken ? `Bearer ${customToken}` : `Token ${DEFAULT_TOKEN}`;
  return token;
};

const makeURL = (path, params = {}, backendUrl = BACKEND_URL) => {
  let queryParamsStr = new URLSearchParams(params);
  !!queryParamsStr && (queryParamsStr = `?${queryParamsStr}`);
  const url = `${backendUrl}${path}${queryParamsStr}`;
  return url;
};

const _GET = (path, params = {}) => {
  const url = makeURL(path, params);
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      reCaptcha: getRecaptcha()
    }
  };
  const request = window.fetch(url, options);

  return request;
};

const __GET = (path, params = {}) =>
  new Promise((resolve, reject) => {
    const request = _GET(path, params);
    request
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => {
        reject(error);
      });
  });

/*
 * @Authors: @EdiedRamos - @JuanCarlos - @JuanSebatian
 * Recursive function to handle the fetch errors, making another call
 * until to get a good result or reach the max iteration count
 */
const apiSaveCaller = async (apiCall, path, params = {}, counter, maxIteration) => {
  if (counter === maxIteration) {
    return new Promise((_, error) => error('Max iteration count reached'));
  }
  try {
    const response = await apiCall(path, params);
    return response;
  } catch (Exception) {
    return apiSaveCaller(apiCall, path, params, counter + 1, maxIteration);
  }
};

const GET = (path, params = {}) => {
  return apiSaveCaller(__GET, path, params, 0, 3);
};

const _POST = (path, body = {}, params = {}) => {
  const url = makeURL(path, params);
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      reCaptcha: getRecaptcha()
    },
    body: JSON.stringify(body)
  };

  const request = window.fetch(url, options);

  return request;
};

const POST = (path, body = {}, params = {}) =>
  new Promise((resolve, reject) => {
    const request = _POST(path, body, params);

    request
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => {
        console.debug('>>>> HttpClient.POST: error=', error);
        reject(error);
      });
  });

const _PUT = (path, body = {}, params = {}, isUserToken = false) => {
  const url = makeURL(path, params);
  const request = fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(isUserToken),
      reCaptcha: getRecaptcha()
    },
    body: JSON.stringify(body)
  });
  return request;
};

const PUT = (path, body = {}, params = {}, isUserToken = false) =>
  new Promise((resolve, reject) => {
    const request = _PUT(path, body, params, isUserToken);
    // INFO: 'status' === HTTP_STATUS
    request
      .then((data) => data.json())
      .then((response) => resolve(response))
      .catch((error) => {
        console.debug('>>>> HttpClient.POST: error=', error);
        reject(error);
      });
  });

const _PATCH = (path, body = {}, params = {}, isUserToken = false) => {
  const url = makeURL(path, params);
  const request = fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(isUserToken),
      reCaptcha: getRecaptcha()
    },
    body: JSON.stringify(body)
  });
  return request;
};

const PATCH = (path, body = {}, params = {}, isUserToken = false) =>
  new Promise((resolve, reject) => {
    const request = _PATCH(path, body, params, isUserToken);
    // INFO: 'status' === HTTP_STATUS
    request
      .then((data) => data.json())
      .then((response) => resolve(response))
      .catch((error) => {
        console.debug('>>>> HttpClient.PATCH: error=', error);
        reject(error);
      });
  });

export const HttpClient = {
  GET,
  POST,
  PUT,
  PATCH
};
