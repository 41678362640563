import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// * import some icons
import {
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faTiktok
} from '@fortawesome/free-brands-svg-icons';

// * assign the above icons to an object for a better manipulation
const ICON_RELATIONAL_NAME = {
  facebook: faFacebookSquare,
  instagram: faInstagram,
  linkedin: faLinkedin,
  tiktok: faTiktok
};

import { View } from 'react-native-web';
import { Col, Image } from 'react-bootstrap';
import { HttpClient } from '../../../Domain/Utils/ApiUtilies/Fetch/Fetch';
import {
  API_VIRTUAL_CAMPUS_SPONSOR_MEDIA,
  FRONTENDLMS_URL,
  FRONTEND_URL
} from '../../../Domain/Redux/Paths';

import { Logo } from '../../../Resources/Images/ImagesLogo';

import './Footer.css';
import Spinner from '../../Atoms/Spinner/Spinner';

import { COMPONENTS } from '../../../Domain/Settings/Constants/ComponentsParameter/ComponentsParameter';
import { useGetComponentParameters } from '../../../Core/Hooks/HooksParameters/useGetComponentParameters';
import ParagraphWithLoading from '../../Molecules/ParagraphWithLoading/ParagraphWithLoading';

// Component that return the footer getting some data from api parameters
const Footer = () => {
  const [iconList, setIconList] = useState([]);

  const parameters = useGetComponentParameters(COMPONENTS.FOOTER);

  // Getting data from apis
  useEffect(() => {
    HttpClient.GET(API_VIRTUAL_CAMPUS_SPONSOR_MEDIA).then((response) => {
      setIconList(() => response?.sort((a, b) => a?.id - b?.id));
    });
  }, []);

  return (
    <View>
      <div id="lt-footer" className="lt-footer py-3 bcyan">
        <div className="row footer-section mb-2 mb-lg-4">
          <Image
            alt="footer image"
            src={parameters?.LOGO_NO_BG?.desktop_image || Logo}
            className="w-100"
            style={{ maxWidth: 160, height: 76 }}
          />
        </div>
        <div className="row footer-section py-2">
          <Col className="col-10 col-md-4 footer-section-divider py-4 py-md-0">
            <div className="row d-flex flex-column">
              <Link
                to="/lite-thinking"
                className="nav-link-clickeable"
                // * parameter code: FOOTER_TRU
              >
                {parameters?.INF_MSG_45?.value || <Spinner backgroundColor="white" />}
              </Link>
            </div>
            <div className="row d-flex flex-column">
              <span
                className="nav-link-clickeable"
                onClick={() => {
                  window.open(`${FRONTEND_URL}/english-challenge`, '_blank');
                }}
                // * parameter code: FOOTER_SLI :'plataformas Digitales
              >
                {parameters?.ENG_CHALLENGE?.value || <Spinner backgroundColor="white" />}
              </span>
              <Link
                to="/:language/jobs"
                className="nav-link-clickeable"
                // * parameter code: FOOTER_TRU
              >
                {parameters?.INF_MSG_44?.value || <Spinner backgroundColor="white" />}
              </Link>
            </div>
          </Col>
          <Col className="col-10 col-md-4 footer-section-divider py-4 py-md-0">
            <div className="row d-flex flex-column">
              <Link
                to="/policies-of-usage"
                className="nav-link-clickeable"
                // * parameter code: FOOTER_TRU
              >
                {parameters?.FOOTER_TRU?.value || <Spinner backgroundColor="white" />}
              </Link>
              <Link
                to="/privacity-policies"
                className="nav-link-clickeable"
                // * parameter code: FOOTER_PRI
              >
                {parameters?.FOOTER_PRI?.value || <Spinner backgroundColor="white" />}
              </Link>
              <ParagraphWithLoading
                // * parameter code: FOOTER_EML
                parameter={parameters?.FOOTER_EML_2}
                className={'m-0'}
                spinnerBg={'white'}
              />
            </div>
          </Col>
          <Col className="col-10 col-md-4 footer-section-divider py-md-0">
            <div className="row d-flex flex-column pt-3 pt-lg-0 w-100 mx-lg-3 ">
              <div style={{ marginTop: '-7px', marginBottom: '2px', marginLeft: '10px' }}>
                <a
                  href={`${FRONTEND_URL}/register`}
                  target="_blank"
                  className="nav-link-clickeable"
                  // * parameter code: FOOTER_CNU
                >
                  {parameters?.TXT_DEMO?.value || <Spinner backgroundColor="white" />}
                </a>
              </div>

              <div className="d-flex align-items-center justify-content-center mx-3 pt-2">
                {iconList?.map((iconInfo) => (
                  <a
                    key={iconInfo?.id}
                    href={iconInfo?.url}
                    aria-label={iconInfo?.name}
                    target="_blank"
                    className="nav-link-clickeable">
                    <img
                      id={iconInfo?.name?.toLowerCase()}
                      className="social-icon"
                      src={iconInfo?.logo}
                      alt={iconInfo?.name}
                      style={{ width: '32px', height: '32px' }}
                    />
                  </a>
                ))}
              </div>
            </div>
          </Col>
        </div>
        <div className="row footer-section mt-4 mt-md-2">
          <span
            style={{ opacity: '0.7', marginTop: '0.8rem' }}
            // * parameter code: FOOTER_RGT
          >
            {parameters?.FOOTER_RGT?.value || <Spinner backgroundColor="white" />}
          </span>
        </div>
      </div>
    </View>
  );
};

export default Footer;
