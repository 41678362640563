import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { HttpClient } from '../../../Domain/Utils/ApiUtilies/Fetch/Fetch';

import { API_PARAMETERS_COMPONENT } from '../../../Domain/Redux/Paths';

import { getParametersAndDuplicateListAsObject } from '../../../Domain/Utils/ApiUtilies/Parameters/Parameters';

export const useGetComponentParameters = (component, wait = false) => {
  const [parameters, setParameters] = useState({});

  const language = useSelector((store) => store.languageTraslate.language);

  useEffect(() => {
    if (!language?.value || wait) {
      return;
    }
    HttpClient.GET(API_PARAMETERS_COMPONENT, { component, language: language?.value }).then(
      (response) => setParameters(() => getParametersAndDuplicateListAsObject(response[component]))
    );
  }, [language, wait]);

  return parameters;
};
