import { TRANSPARENT_BACKGROUND } from '../Actions/ActionTypes/ActionTypesStyles';

const initialState = {
  transparentBackground: false
};

export default function stylesState(state = initialState, action) {
  switch (action.type) {
    case TRANSPARENT_BACKGROUND:
      return {
        ...state,
        transparentBackground: action.payload
      };
    default:
      return state;
  }
}
