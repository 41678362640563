import { createStore, applyMiddleware, compose } from 'redux';
import { combineReducers } from 'redux';
import generalReducer from '../Reducers/Reducer';
import reduxThunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import stylesState from '../Reducers/StylesState';
import languageTraslate from '../Reducers/LanguageTraslate';
import mobileMenu from '../Reducers/MobileMenu';
import generalData from '../Reducers/GeneralData';

const reducers = combineReducers({
  stylesState,
  generalReducer,
  languageTraslate,
  mobileMenu,
  generalData
});

const logger = createLogger({
  // predicate: (getState, action) => action.type === ''
});

export default createStore(reducers, compose(applyMiddleware(reduxThunk, logger)));
