import { useSelector } from 'react-redux';
import { HttpClient } from '../../Utils/ApiUtilies/Fetch/Fetch';
import { GET_PORTFOLIOS_LIST_PATH } from '../Paths';

const actionType = {
  // TODO: Pendiente actualizar las referencias de actionTypes de los elementos asociados a Portfolios fuera de esta implementación
  LOADER: 'GET_PORTFOLIOS_DATA_LOADER',
  LIST: 'GET_PORTFOLIOS_DATA',
  ITEM: 'GET_PORTFOLIOS_DATA_ITEM'
};

// ------------------------------------
// Pure Actions
// ------------------------------------
const setLoader = (loader) => ({
  type: actionType.LOADER,
  payload: {
    loader
  }
});
const setList = (count, results) => ({
  type: actionType.LIST,
  payload: {
    count,
    results
  }
});
const setItem = (item) => ({
  type: actionType.ITEM,
  payload: {
    item
  }
});

// ------------------------------------
// Actions
// ------------------------------------
const getPortfolios =
  (unpaginated = false) =>
  async (dispatch, getStore) => {
    dispatch(setLoader(true));
    const languageKey = useSelector((store) => store.languageTraslate.language) || {
      id: 3,
      name: 'Ingles',
      description: 'En',
      value: 3
    };
    try {
      const response = await HttpClient.GET(GET_PORTFOLIOS_LIST_PATH, {
        unpaginated,
        language: languageKey.value
      });
      dispatch(setList(response.count, response.results));
    } catch (error) {
      console.debug('>>>> portfolios.getPortfolios: error=', error);
    } finally {
      dispatch(setLoader(false));
    }
  };
const getPortfoliosByStrategicLine =
  (strategicLineId, unpaginated = false) =>
  async (dispatch, getStore) => {
    dispatch(setLoader(true));
    try {
      const response = await HttpClient.GET(GET_PORTFOLIOS_LIST_PATH, {
        unpaginated,
        strategic_line__id: strategicLineId,
        language: languageKey.value
      });
      dispatch(setList(response.count, response.results));
    } catch (error) {
      console.debug('>>>> portfolios.getPortfoliosByStrategicLine: error=', error);
    } finally {
      dispatch(setLoader(false));
    }
  };
const getPortfolioById = (portfolioId) => async (dispatch, getStore) => {
  dispatch(setLoader(true));
  try {
    const response = await HttpClient.GET(GET_PORTFOLIOS_LIST_PATH, {
      id: portfolioId,
      language: languageKey.value
    });
    const item = response.results[0] || null;
    dispatch(setItem(item));
  } catch (error) {
    console.debug('>>>> portfolios.getPortfolioById: error=', error);
  } finally {
    dispatch(setLoader(false));
  }
};

export const actions = {
  getPortfolios,
  getPortfoliosByStrategicLine,
  getPortfolioById
};

export const reducers = {
  [actionType.LOADER]: (state, { loader }) => {
    return {
      ...state,
      portfolios: { ...state.portfolios, loader }
    };
  },
  [actionType.LIST]: (state, { count, results }) => {
    return {
      ...state,
      portfolios: { ...state.portfolios, count, results }
    };
  },
  [actionType.ITEM]: (state, { item }) => {
    return {
      ...state,
      portfolios: { ...state.courses, item }
    };
  }
};

export const initialState = {
  portfolios: {
    loader: false,
    count: 0,
    item: null,
    results: []
  }
};
