export const BLOCK_SCREEN = 'BLOCK_SCREEN';
export const IS_LOGIN = 'IS_LOGIN';
export const REGISTER = 'REGISTER';
export const REGISTER_TO_COURSE = 'REGISTER_TO_COURSE';
export const LOCATION = 'LOCATION';
export const REGISTER_CLEAN = 'REGISTER_CLEAN';
export const COURSES_LIST = 'COURSES_LIST';
export const INTRODUCTORY_BANNER_RANDOM_LIST = 'INTRODUCTORY_BANNER_RANDOM_LIST';
export const GENERAL_BANNER_LIST = 'GENERAL_BANNER_LIST';
export const COURSES_ITEM = 'COURSES_ITEM';
export const COURSES_LIST_CLEAN = 'COURSES_LIST_CLEAN';
export const MESSAGE_CLEAN = 'MESSAGE_CLEAN';
export const PROFILE = 'PROFILE';
export const LOGIN = 'LOGIN';
export const PARAMETERS = 'PARAMETERS';
export const GENERAL_ERROR = 'LOGIN_ERROR';
export const GET_PARTNERS_LIST = 'GET_PARTNERS_LIST';
export const GET_STRATEGIC_LINES_LIST = 'GET_STRATEGIC_LINES_LIST';
export const GET_PORTFOLIOS_DATA_LOADER = 'GET_PORTFOLIOS_DATA_LOADER';
export const GET_PORTFOLIOS_DATA_ITEM = 'GET_PORTFOLIOS_DATA_ITEM';
export const GET_PORTFOLIOS_DATA = 'GET_PORTFOLIOS_DATA';
export const GET_STRATEGIC_LINES_DATA_LOADER = 'GET_STRATEGIC_LINES_DATA_LOADER';
export const GET_STRATEGIC_LINES_DATA = 'GET_STRATEGIC_LINES_DATA';
export const COURSES_DATA_LOADER = 'COURSES_DATA_LOADER';
export const COURSES_DATA_LIST = 'COURSES_DATA_LIST';
export const COURSES_DATA_ITEM = 'COURSES_DATA_ITEM';
export const UPDATEPERFIL = 'UPDATEPERFIL';
