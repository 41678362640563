import { IS_OPEN_MOBILE_MENU } from '../Actions/ActionTypes/ActionTypeMobileMenu';

const initialState = {
  mobileMenuOpened: false
};

export default function mobileMenu(state = initialState, action) {
  switch (action.type) {
    case IS_OPEN_MOBILE_MENU:
      return {
        ...state,
        mobileMenuOpened: action.payload
      };
    default:
      return state;
  }
}
