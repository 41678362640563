import {
  LOGIN,
  LOCATION as LOCATE,
  REGISTER,
  PROFILE,
  MESSAGE_CLEAN,
  BLOCK_SCREEN,
  COURSES_LIST,
  COURSES_ITEM,
  PARAMETERS,
  IS_LOGIN,
  INTRODUCTORY_BANNER_RANDOM_LIST,
  GENERAL_BANNER_LIST,
  GET_PARTNERS_LIST,
  GET_STRATEGIC_LINES_LIST,
  GET_STRATEGIC_LINES_DATA_LOADER,
  GET_STRATEGIC_LINES_DATA,
  GET_PORTFOLIOS_DATA,
  GENERAL_ERROR
} from '../Actions/ActionTypes/ActionTypes';
import { CHALLENGE_ENUMS } from '../Enums/Enums';
import { LOCATION } from '../../Settings/Location/Location';
import { reducerGeneral } from '../Mappers/Mapper';
import {
  initialState as portfolioInitialState,
  reducers as portfolioReducers
} from '../Modules/Portfolios';
import { initialState as courseInitialState, reducers as courseReducers } from '../Modules/Courses';
import {
  initialState as interestRegistrationInitialState,
  reducers as interestRegistrationReducers
} from '../Modules/Interest-registration';

const deviceLanguage = 'es';
const initialState = {
  message: '',
  login: '',
  token: '',
  status: '',
  blockScreenState: false,
  isLogin: false,
  location: '',
  userData:
    window.localStorage.getItem('username') === 'null' ||
    window.localStorage.getItem('username') === undefined ||
    window.localStorage.getItem('username') === 'undefined' ||
    window.localStorage.getItem('username') === null
      ? ''
      : {
          username: window.localStorage.getItem('username'),
          email: window.localStorage.getItem('email'),
          first_name: window.localStorage.getItem('first_name'),
          pk: window.localStorage.getItem('pk'),
          last_name: window.localStorage.getItem('last_name')
        },
  itemsList: [],
  introductoryBannerList: [],
  generalBannerList: [],
  partnersList: [],
  strategicLinesList: [],
  portfoliosList: [],
  strategic_lines: {
    loader: false,
    count: 0,
    results: []
  },
  ...portfolioInitialState,
  ...courseInitialState,
  ...interestRegistrationInitialState,
  consultant: '',
  challengeState: {
    questionList: [],
    answeredQuestions: [],
    unansweredQuestions: [],
    currentQuestion: null
  }
};

const reducers = {
  [GET_STRATEGIC_LINES_DATA_LOADER]: (state, { loader }) => {
    return {
      ...state,
      strategic_lines: { ...state.strategic_lines, loader }
    };
  },
  [GET_STRATEGIC_LINES_DATA]: (state, { count, results }) => {
    return {
      ...state,
      strategic_lines: { ...state.strategic_lines, count, results }
    };
  },
  ...portfolioReducers,
  ...courseReducers,
  ...interestRegistrationReducers
};

const generalReducer = (state = initialState, action) => {
  const reducer = reducers[action.type];
  if (reducer) {
    return reducer(state, action.payload);
  }
  let variables = [];
  if (
    [
      LOGIN,
      PROFILE,
      BLOCK_SCREEN,
      MESSAGE_CLEAN,
      COURSES_LIST,
      COURSES_ITEM,
      PARAMETERS,
      IS_LOGIN,
      INTRODUCTORY_BANNER_RANDOM_LIST,
      GENERAL_BANNER_LIST,
      GET_PARTNERS_LIST,
      GET_STRATEGIC_LINES_LIST
    ].includes(action.type)
  ) {
    if (action.type === LOGIN) {
      variables = [
        { variable: 'token', payload: 'data.access' },
        { variable: 'refresh_token', payload: 'data.refresh' }
      ];
    }
    if (action.type === COURSES_LIST) {
      variables = [
        { variable: 'itemsList', payload: 'data.results' },
        { variable: 'quantity', payload: 'data.count' }
      ];
    }
    if (action.type === INTRODUCTORY_BANNER_RANDOM_LIST) {
      variables = [
        { variable: 'introductoryBannerList', payload: 'data.results' },
        { variable: 'quantity', payload: 'data.count' }
      ];
    }
    if (action.type === GENERAL_BANNER_LIST) {
      variables = [
        { variable: 'generalBannerList', payload: 'data.results' },
        { variable: 'quantity', payload: 'data.count' }
      ];
    }
    if (action.type === COURSES_ITEM) {
      variables = [{ variable: 'consultant', payload: 'data.results[0]' }];
    }
    if (action.type === PARAMETERS) {
      variables = [{ variable: 'pageSize', payload: 'data.results[0].value' }];
    }
    if (action.type === PROFILE) {
      variables = [{ variable: 'userData', payload: 'data' }];
    }
    if (action.type === BLOCK_SCREEN) {
      variables = [{ name: 'blockScreenState', value: action.payload }];
    }
    if (action.type === GET_PARTNERS_LIST) {
      variables = [
        { variable: 'partnersList', payload: 'data.results' },
        { variable: 'quantity', payload: 'data.count' }
      ];
    }
    if (action.type === GET_STRATEGIC_LINES_LIST) {
      variables = [
        { variable: 'strategicLinesList', payload: 'data.results' },
        { variable: 'quantity', payload: 'data.count' }
      ];
    }
    if (action.type === GET_PORTFOLIOS_DATA) {
      variables = [
        { variable: 'portfolios.results', payload: 'data.results' },
        { variable: 'portfolios.count', payload: 'data.count' }
      ];
    }
    if (action.type === MESSAGE_CLEAN) {
      for (const variable of action.payload.variables) {
        variables.push({ name: variable, value: '' });
      }
    }
    return reducerGeneral(
      deviceLanguage,
      action.payload && action.payload.status ? action.payload.status : null,
      state,
      action.payload,
      variables,
      action.type
    );
  } else {
    switch (action.type) {
      case REGISTER:
        if (
          typeof action.payload === 'object' &&
          action.payload.status === 400 &&
          action.payload.data.hasOwnProperty('username')
        ) {
          return {
            ...state,
            message: LOCATION[REGISTER][deviceLanguage].errors.USERALREADY,
            status: action.payload.status
          };
        }
        if (
          typeof action.payload === 'object' &&
          action.payload.status === 400 &&
          (action.payload.data.hasOwnProperty('password') ||
            (action.payload.data.hasOwnProperty('non_field_errors') &&
              (action.payload.data.non_field_errors + '').includes('password')))
        ) {
          return {
            ...state,
            message: LOCATION[REGISTER][deviceLanguage].errors.PASSWORD,
            status: action.payload.status
          };
        }
        if (
          typeof action.payload === 'object' &&
          action.payload.status === 400 &&
          action.payload.data.hasOwnProperty('email')
        ) {
          return {
            ...state,
            message: LOCATION[REGISTER][deviceLanguage].errors.EMAILALREADY,
            status: action.payload.status
          };
        }
        if (
          typeof action.payload === 'object' &&
          action.payload.status === 400 &&
          action.payload.data.hasOwnProperty('error')
        ) {
          return {
            ...state,
            message: LOCATION[REGISTER][deviceLanguage].errors.RECAPTCHA,
            status: action.payload.status
          };
        }
        if (action.payload.status === 201) {
          return {
            ...state,
            message: LOCATION[REGISTER][deviceLanguage].messages.CREATE,
            status: action.payload.status
          };
        } else {
          return { message: LOCATION.general[deviceLanguage].SERVICEERROR };
        }
      case LOCATE:
        if (typeof action.payload === 'object') {
          window.localStorage.removeItem('location');
          window.localStorage.removeItem('country');
          window.localStorage.removeItem('currency');
          return {
            ...state,
            country: action.payload.country.toLowerCase(),
            location: action.payload.country_name,
            currency: action.payload.country_code === 'CO' ? 'cop' : 'usd'
          };
        }
        return { ...state };
      case GENERAL_ERROR:
        return {
          ...state,
          status: action.payload.status,
          message: action.payload.message
        };

      case CHALLENGE_ENUMS.CURRENT_QUESTION:
        return {
          ...state,
          challengeState: {
            ...state.challengeState,
            currentQuestion: action.payload
          }
        };

      case CHALLENGE_ENUMS.QUESTION_LIST:
        return {
          ...state,
          challengeState: {
            ...state.challengeState,
            questionList: action.payload
          }
        };

      case CHALLENGE_ENUMS.UNANSWERED_QUESTIONS:
        return {
          ...state,
          challengeState: {
            ...state.challengeState,
            unansweredQuestions: action.payload
          }
        };

      case CHALLENGE_ENUMS.ANSWERED_QUESTIONS: {
        let answeredQuestion = action.payload;

        if (!answeredQuestion.id) return state;

        const aux = state.challengeState.unansweredQuestions.find(
          (item) => item.id === +answeredQuestion.id
        );

        answeredQuestion.position = aux?.position;

        let newUnansweredQuestions = state.challengeState.unansweredQuestions;
        let newAnsweredQuestions = state.challengeState.answeredQuestions;

        if (answeredQuestion.value?.length > 0) {
          newUnansweredQuestions = state.challengeState.unansweredQuestions
            .filter((question) => question.id != answeredQuestion.id)
            .sort((a, b) => a.position - b.position);
          if (
            !state.challengeState.answeredQuestions.find(
              (question) => question.id == answeredQuestion.id
            )
          ) {
            newAnsweredQuestions = [
              ...state.challengeState.answeredQuestions,
              answeredQuestion
            ].sort((a, b) => a.position - b.position);
          }
        } else {
          if (
            !state.challengeState.unansweredQuestions.find(
              (question) => question.id == answeredQuestion.id
            )
          ) {
            newUnansweredQuestions = [
              ...state.challengeState.unansweredQuestions,
              answeredQuestion
            ].sort((a, b) => a.position - b.position);
          }
          newAnsweredQuestions = state.challengeState.answeredQuestions
            .filter((question) => question.id != answeredQuestion.id)
            .sort((a, b) => a.position - b.position);
        }

        return {
          ...state,
          challengeState: {
            ...state.challengeState,
            answeredQuestions: newAnsweredQuestions,
            unansweredQuestions: newUnansweredQuestions
          }
        };
      }

      case CHALLENGE_ENUMS.CLEARN_CHALLENGE_STATE:
        return {
          ...state,
          challengeState: {
            questionList: [],
            answeredQuestions: [],
            unansweredQuestions: [],
            currentQuestion: 1
          }
        };

      default:
        return state;
    }
  }
};

export default generalReducer;
