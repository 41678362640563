import { GET_NEWS } from '../Actions/ActionTypes/ActionTypeGeneralData';

const initialState = {
  news: []
};

export default function generalData(state = initialState, action) {
  switch (action.type) {
    case GET_NEWS:
      return {
        ...state,
        news: action.payload
      };
    default:
      return state;
  }
}
